import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NavBar from "../../../../components/navbars/signedOutNavbar";
import Footer from "../../../../components/footer";
import ROUTES from "../../../../utils/routes";
import SearchableDropDown from "../../../../components/common/searchableDropDown";
import { getCountriesAction } from "../../../../commonData/countries/action";
import { useDispatch, useSelector } from "react-redux";
import { getCountiesAction } from "../../../../commonData/counties/action";
import { getCurriculaAction } from "../../../../commonData/curricula/action";
import { getSchoolsAction } from "../../../../commonData/schools/action";
import { getCategoriesAction } from "../../../../commonData/categories/action";
import { setTeacherPersonalInfo } from "../personalInfo/action";
import InformationAccordion from "../../../../components/common/informationAccordion";
import {
  appendItemToArray,
  backgroundImage, isObjEmpty,
  removeItemFromArray,
} from '../../../../utils/common';
import { createMissingSchoolAction } from "../../../../commonData/missingSchool/action";

export default function TeacherSelectSchool() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.getCountries.payload.data);
  const counties = useSelector((state) => state.getCounties.payload.data);
  const schools = useSelector((state) => state.getSchools.payload.content);
  const teacherPInfo = useSelector(
    (state) => state.setTeacherPersonalInfo.payload
  );
  const curricula = useSelector((state) => state.getCurricula.payload);
  const categories = useSelector((state) => state.getCategories.payload);
  const countriesLoading = useSelector((state) => state.getCountries.loading);
  const countiesLoading = useSelector((state) => state.getCounties.loading);
  const schoolsLoading = useSelector((state) => state.getSchools.loading);
  const missingSchool = useSelector((state) => state.createMissingSchool.result.data);

  const [teacherInfo, setTeacherInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);
  const [selectedCurricula, setSelectedCurricula] = useState([]);
  const [newSchoolDetails, setNewSchoolDetails] = useState({});
  const [pageErrors, setPageErrors] = useState(null);
  const [selectedCurriculaLevels, setSelectedCurriculaLevels] = useState([]);
  const [savedCurriculaLevels, setSavedCurriculaLevels] = useState([]);

  const countriesOptions = [];
  const schoolsOptions = [];
  const countiesOptions = [];
  const categoriesOptions = [];
  const curriculaOptions = [];

  countries &&
    countries.map((country) =>
      countriesOptions.push({ label: country.name, value: country.id })
    );

  schools &&
    schools.map((school) =>
      schoolsOptions.push({ label: school.name, value: school })
    );

  counties &&
    counties.map((county) =>
      countiesOptions.push({ label: county.name, value: county.id })
    );

  curricula["data"] &&
    curricula["data"].map((curriculum) =>
      curriculaOptions.push({ label: curriculum.name, value: curriculum.id })
    );

  categories &&
    categories.map((category) =>
      categoriesOptions.push({
        label: category.level,
        value: category.id,
        selected: true,
      })
    );

  const nextRouteChange = (e) => {
    e.preventDefault();
    if (savedCurriculaLevels.length > 0) {
      setFormFilled(true);
    } else {
      return setFormFilled(false);
    }
    const updatedInfo = {
      ...teacherInfo,
      curricula_levels: savedCurriculaLevels,
    };
    dispatch(setTeacherPersonalInfo({ ...teacherPInfo, ...updatedInfo }));
    let path = ROUTES.teacherSignUpAuthentication;
    navigate(path);
  };

  const backRouteChange = () => {
    let path = ROUTES.teacherSignUpPersonalInfo;
    navigate(path);
  };

  useEffect(() => {
    dispatch(getCountriesAction());
  }, [dispatch]);

  const handleCountryChange = (code) => {
    dispatch(getCountiesAction(code));
    dispatch(getCurriculaAction(code));
  };

  const handleCountyChange = (countyId) => {
    setNewSchoolDetails({ ...newSchoolDetails, countyId: countyId });
    (async () => dispatch(getSchoolsAction(countyId)))();
  };

  const handleSchoolChange = (institutionId) => {
    setTeacherInfo({ ...teacherInfo, institutionId: institutionId });
  };

  const handleChange = (e) => {
    setNewSchoolDetails({
      ...newSchoolDetails,
      [e.target.name]: e.target.value,
    });
  };

  const addNewSchool = (e) => {
    e.preventDefault();
    if (newSchoolDetails.countyId && newSchoolDetails.name) {
      const schoolData = {
        countyId: newSchoolDetails.countyId,
        name: newSchoolDetails.name,
      };
      (async () => {
        const response = await dispatch(createMissingSchoolAction(schoolData));
        if (response) {
          dispatch(
            setTeacherPersonalInfo({
              ...teacherInfo,
              institutionId: response.id,
            })
          );
        }
      })();
    } else {
      setPageErrors(
        "Please enter the missing school name and click the add button"
      );
    }
  };

  const handleCurriculaOnChange = (e) => {
    const { value, checked } = e.target;
    let selectedItems = checked
      ? appendItemToArray(selectedCurricula, value)
      : removeItemFromArray(selectedCurricula, value);
    setSelectedCurricula(selectedItems);
    dispatch(getCategoriesAction(selectedItems));
  };

  useEffect(() => {
    if (missingSchool && !isObjEmpty(missingSchool)) {
      setTeacherInfo({ ...teacherInfo, institutionId: missingSchool });
    }
  }, [missingSchool, dispatch]);

  useEffect(() => {
    if (categoriesOptions.length > 0) {
      setSelectedCurriculaLevels(categoriesOptions);
      setSavedCurriculaLevels(categoriesOptions);
    }
  }, [categories]);

  const handleCurriculaLevelsOnChange = (e, index, action) => {
    e.preventDefault();
    let updatedItems = [...selectedCurriculaLevels];
    if (action === "remove") {
      updatedItems[index].selected = false;
      setSavedCurriculaLevels(
        removeItemFromArray(savedCurriculaLevels, updatedItems[index])
      );
    } else {
      updatedItems[index].selected = true;
      setSavedCurriculaLevels([...savedCurriculaLevels, updatedItems[index]]);
    }
    setSelectedCurriculaLevels(updatedItems);
    setTeacherInfo({ ...teacherInfo, curricula_levels: savedCurriculaLevels });
  };

  return (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                {!formFilled ? (
                  <div className="surface text-center text-secondary-red-500 text-md py-4 rounded-md">
                    Please fill in the missing fields
                  </div>
                ) : null}

                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-2 sm:p-3 md:p-4 lg:p-5 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">Select your school</h6>
                  </div>
                  <div className="flex flex-col gap-6">
                    <form className="flex flex-col gap-6">
                      <SearchableDropDown
                        id="countryIso2Code"
                        name="countryIso2Code"
                        options={
                          countriesOptions.length > 0 ? countriesOptions : []
                        }
                        handleChange={handleCountryChange}
                        loading={countriesLoading}
                        borderText={`School Country ${!formFilled && !teacherPInfo.institutionId ? "*" : ""}`}
                      />

                      <SearchableDropDown
                        id="county"
                        name="county"
                        options={
                          countiesOptions.length > 0 ? countiesOptions : []
                        }
                        handleChange={handleCountyChange}
                        loading={countiesLoading}
                        borderText={`County/State ${!formFilled && !teacherPInfo.institutionId ? "*" : ""}`}
                      />

                      <SearchableDropDown
                        id="institutionId"
                        name="institutionId"
                        options={
                          schoolsOptions.length > 0 ? schoolsOptions : []
                        }
                        handleChange={handleSchoolChange}
                        loading={schoolsLoading}
                        borderText={`School (Optional) ${!formFilled && !teacherPInfo.institutionId ? "*" : ""}`}
                      />
                      <div>
                        <div className="flex items-center justify-center gap-4">
                          <p className="text-sm font-medium inline-block">
                            Can't find your school?
                          </p>

                          <input
                            type="text"
                            name="name"
                            id="school"
                            value={newSchoolDetails.name || ''}
                            className="line-clamp-1 flex-1 text-xs py-4 surface w-full my-auto focus:outline-none focus:shadow-none focus:ring-0 outline-none rounded-md borders text-app-colors-p-normal dark:text-app-colors-p-normal-d dark:placeholder-app-colors-p-small-d placeholder-app-colors-p-small"
                            placeholder="Enter school name"
                            onChange={handleChange}
                          />

                          <button
                              onClick={addNewSchool}
                              className="pill-button primary-button opacity-50 hover:opacity-70"
                          >
                            Add
                          </button>
                        </div>
                        {pageErrors && (
                            <div className="text-secondary-red-500 text-sm py-2 text-right">
                              {pageErrors}
                            </div>
                        )}
                      </div>

                      {curriculaOptions.length > 0 && (
                          <div>
                            <p className="text-sm font-medium inline-block">
                              Select Curriculums
                            </p>
                            <div className="flex justify-between mt-2">
                              {curriculaOptions.map((curriculumOption, index) => (
                              <div
                                className="group flex items-center mb-4"
                                key={curriculumOption.value}
                              >
                                <input
                                  id={curriculumOption.label.replace(
                                    /\s+/g,
                                    "_"
                                  )}
                                  type="checkbox"
                                  value={curriculumOption.value}
                                  className="surface borders w-4 h-4 rounded focus:ring-0 cursor-pointer focus:outline-none"
                                  onChange={(e) =>
                                    handleCurriculaOnChange(e, index)
                                  }
                                />
                                <label
                                  htmlFor={curriculumOption.label.replace(
                                    /\s+/g,
                                    "_"
                                  )}
                                  className="ml-2 text-xs font-medium text-gray-900"
                                >
                                  <p className="smaller group-hover:text-secondary-red-500">
                                    {curriculumOption.label}
                                  </p>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="">
                        <span className="block">
                          <div
                            className={`borders flex flex-col min-h-fit max-h-28 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 borders ${!formFilled && savedCurriculaLevels.length <= 0 ? "border-secondary-red-500" : "borders"}  focus-within:border-i-purple-500 focus:text-i-purple-500 rounded relative`}
                          >
                            <div className="relative">
                              <span
                                className={` absolute py-0 px-1 -top-3 inline-flex ml-2 surface text-xs md:text-sm ${!formFilled && savedCurriculaLevels.length <= 0 ? "text-secondary-red-500" : "text-gray-400"}`}
                              >
                                Curriculum Levels
                              </span>
                            </div>

                            <div className="overflow-y-auto my-0.5">
                              {selectedCurriculaLevels.length > 0 ? (
                                <div className="p-2 text-xs grid grid-cols-1 gap-1">
                                  {selectedCurriculaLevels.map(
                                    (level, index) => (
                                      <div className="flex w-full justify-between items-center gap-2 group" key={level.value}>
                                        <span
                                          className={`${level.selected ? "green-button group-hover:bg-secondary-green-500" : "red-button group-hover:bg-secondary-red-600"} text-white text-sm font-medium rounded px-3 py-1 flex-1 justify-between items-center line-clamp-1 `}
                                        >
                                          {level.label}
                                        </span>
                                        {level.selected ? (
                                          <button
                                            className="flex gap-2 items-center justify-end text-secondary-red-500 hover:text-secondary-red-600 font-semibold rounded-md h-8 w-16 cursor-pointer"
                                            onClick={(e) => handleCurriculaLevelsOnChange(e, index, "remove")}
                                          >
                                            <svg
                                              className="icons-two group-hover:text-secondary-red-500"
                                              width="12"
                                              height="12"
                                              viewBox="0 0 66 64"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M56.8571 28V37.1429H66V28H56.8571Z"
                                                fill="#"
                                              />
                                              <path
                                                d="M2 28V37.1429H47.7143V28H2Z"
                                                fill="#"
                                              />
                                            </svg>
                                            Remove
                                          </button>
                                        ) : (
                                          <button
                                            className="flex gap-2 items-center justify-end text-secondary-green-400 hover:text-secondary-green-500 font-semibold rounded-md h-8 w-16 cursor-pointer"
                                            onClick={(e) =>
                                              handleCurriculaLevelsOnChange(
                                                e,
                                                index,
                                                "add"
                                              )
                                            }
                                          >
                                            <svg
                                              className="icons-two group-hover:text-secondary-green-500"
                                              width="12"
                                              height="12"
                                              viewBox="0 0 64 64"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M36.5714 0H27.4286V27.4286H0V36.5714H27.4286V64H36.5714V36.5714H45.7143V27.4286H36.5714V0ZM54.8571 27.4286V36.5714H64V27.4286H54.8571Z"
                                                fill="#"
                                              />
                                            </svg>
                                            Add
                                          </button>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <p className="smaller line-clamp-1 pl-3 py-4 text-sm">
                                  Choose at least one curriculum to display
                                  levels
                                </p>
                              )}
                            </div>
                          </div>
                        </span>
                      </div>
                    </form>
                    <div className="flex justify-end">
                      <button
                        className="hidden outline-pill-button primary-light-button px-9"
                        onClick={backRouteChange}
                      >
                        Back
                      </button>
                      <button
                        className="pill-button primary-button px-9"
                        onClick={(e) => nextRouteChange(e)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
