import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import NavBar from "../../../components/navbars/signedOutNavbar";
import Footer from "../../../components/footer";
import ROUTES from "../../../utils/routes";
import { setAccountType } from "./action";
import TermsAndConditions from "../../../components/common/termsAndConditions";
import InformationAccordion from "../../../components/common/informationAccordion";
import { backgroundImage } from "../../../utils/common";

export default function AccountType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [accountType, setAccountTypeState] = useState("");

  const handleCheckboxChange = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const handleShowModal = (type) => {
    setAccountTypeState(type);
    setShowModal(true);
  };

  const handleHideModal = () => setShowModal(false);

  const handleContinue = (e) => {
    e.preventDefault();
    if (acceptedTerms) {
      dispatch(setAccountType(accountType));
      navigate(ROUTES.register);
    }
  };

  const loginRouteChange = () => {
    navigate(ROUTES.home);
  };

  const dark =
    localStorage.getItem("color-theme") === "dark" ||
    (!("color-theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches);

  return (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {showModal && (
        <div className="z-30 fixed inset-0 bg-primary-950 bg-opacity-90 w-full h-full max-h-screen flex items-center justify-center">
          <div className="surface max-h-800 w-full xl:w-3/4 rounded-lg shadow-lg p-4 py-4 md:py-6 lg:py-8 mx-8 md:px-12 lg:px-16">
            <div className="flex justify-between items-center w-full py-2 border-b border-app-colors-border dark:border-app-colors-border-d mb-6">
              <div className="flex flex-col justify-center">
                <img
                  id="logo-light"
                  className={`h-8 md:h-8 lg:h-16 ${dark ? "block" : "hidden"}`}
                  src="/images/logo-light.svg"
                  alt="Dark mode logo"
                />
                <img
                  id="logo-dark"
                  className={`h-8 md:h-8 lg:h-16 ${dark ? "hidden" : "block"}`}
                  src="/images/logo-dark.svg"
                  alt="Light mode logo"
                />
                <h5 className="text-primary-main-700 dark:text-primary-main-700 mt-4 font-bold uppercase text-sm md:text-base lg:text-lg">
                  Terms of Use
                </h5>
              </div>
              <svg
                onClick={handleHideModal}
                className="icons hover:text-secondary-red-500 cursor-pointer"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.94167 11.1639H11.1639V8.94167H8.94167V11.1639ZM19.4806 2.19611L17.9094 0.625L11.6239 6.91056L13.195 8.48167L19.4806 2.19611ZM0.625 17.9094L2.19611 19.4806L8.48167 13.1961L6.90944 11.625L0.625 17.9094ZM11.6239 13.1961L17.9094 19.4806L19.4806 17.9094L13.195 11.625L11.6239 13.1961ZM0.625 2.19722L6.91056 8.48167L8.48278 6.91056L2.19611 0.625L0.625 2.19722Z"
                />
              </svg>
            </div>

            <div id="terms-and-condition" className="max-h-400 overflow-y-auto">
              <TermsAndConditions />
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-between items-center w-full py-4 lg:py-6 border-t border-app-colors-border dark:border-app-colors-border-d mt-6">
              <div className="flex gap-4 items-center">
                <input
                  id="accept-terms"
                  type="checkbox"
                  className="w-6 h-6 text-primary-main-700 rounded focus:ring-0 focus:outline-none"
                  checked={acceptedTerms}
                  onChange={handleCheckboxChange}
                />
                <p className="small font-medium ml-2">
                  By using our platform, you acknowledge that you have read,
                  understood, and agree to be bound by these Terms of Use.
                </p>
              </div>
              <button
                className={`${
                  acceptedTerms
                    ? "primary-button cursor-pointer"
                    : "disabled-button"
                } flex pill-button px-12`}
                disabled={!acceptedTerms}
                onClick={e => handleContinue(e)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-4 sm:p-5 md:p-6 lg:p-7 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">
                      I want to register as a ...
                    </h6>
                  </div>
                  <div>
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col gap-4">
                        <button
                          className="h-16 w-full cursor-pointer"
                          onClick={() => handleShowModal("student")}
                        >
                          <span className="rounded-button green-button uppercase flex w-full group-hover:bg-secondary-green-500 h-full">
                            Student
                          </span>
                        </button>
                        <button
                          className="h-16 w-full cursor-pointer"
                          onClick={() => handleShowModal("teacher")}
                        >
                          <span className="rounded-button yellow-button uppercase flex w-full group-hover:bg-secondary-yellow-600 h-full">
                            Teacher
                          </span>
                        </button>
                        <button
                          className="h-16 w-full cursor-pointer"
                          onClick={() => handleShowModal("institution")}
                        >
                          <span className="rounded-button red-button uppercase flex w-full group-hover:bg-secondary-red-600 h-full">
                            School
                          </span>
                        </button>
                        <button
                          className="h-16 w-full cursor-pointer"
                          onClick={() => handleShowModal("other")}
                        >
                          <span className="rounded-button blue-button uppercase flex w-full group-hover:bg-secondary-blue-600 h-full">
                            Other e.g. parents
                          </span>
                        </button>
                      </div>
                      <p className="hidden small text-center">
                        By signing up, you agree to our terms of use.
                      </p>
                    </div>
                  </div>
                </div>
                <span className="flex gap-2 px-4">
                  <p className="text-white dark:text-white">
                    Already have an account?
                  </p>
                  <button
                    className="font-medium text-white hover:text-secondary-yellow-500 cursor-pointer"
                    onClick={loginRouteChange}
                  >
                    Sign in
                  </button>
                </span>
              </div>
            </div>
            <div className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
