import React from 'react';
import ReactPlayer from 'react-player';

const VideoItem = ({
  videoLink,
  title,
  duration,
  views,
  dateUploaded = "",
  handleVideoItemClick,
}) => {
  return (
    <div className="group min-w-full" onClick={handleVideoItemClick}>
      <div className="w-full rounded" style={{ aspectRatio: "16/9" }}>
        <ReactPlayer
          className="react-player rounded"
          url={videoLink}
          width="100%"
          height="100%"
          controls={true}
          light={true}
        />
      </div>

      <div className="py-4 px-2 cursor-pointer">
        <h6 className="text-hover w-full text-base line-clamp-2">{title}</h6>
        <div>
          <div className="flex mt-2 gap-2 items-center">
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.2476 80.8105H71.3819L79.9062 99.9996H24.5163L12.2476 80.8105Z"
                  fill="url(#paint0_linear_60896_95464)"
                  stroke="#2195F2"
                  strokeWidth="0.000655023"
                />
                <path
                  d="M25.0001 51.0001C29.0002 50 45.5001 50.5001 47.1627 51.0001C48.8253 51.5001 63.0001 77.0001 63.0001 77.0001H41.0309C41.0309 77.0001 20.9999 52.0003 25.0001 51.0001Z"
                  fill="url(#paint1_linear_60896_95464)"
                />
                <path
                  d="M50.7749 47.9849L66.715 73.8391C70.1006 79.3241 70.1006 86.2006 66.715 91.6857L61.5907 99.9985H82.4171C88.5662 99.9985 94.2669 96.8476 97.4513 91.6857L97.7715 91.174C100.846 85.7697 100.736 79.1536 97.4604 73.8391L50.7749 0.000213623L35.3884 24.9271L50.7749 47.9849Z"
                  fill="url(#paint2_linear_60896_95464)"
                />
                <path
                  d="M27.8518 50.6155L44 51C44.1572 51 40.5 51 47 51C44 51 43.4715 53.3272 40.9088 57.4204L27.2911 78.5845C23.3111 84 22.7426 86.5 27.2911 91.5L34.1139 99.9985H14.6123C9.50212 99.9985 4.7645 97.4191 2.11813 93.1937L1.85197 92.7748C-0.703144 88.3509 -0.61189 82.9349 2.11052 78.5845L15.3576 57.4204C17.9964 53.1949 22.7416 50.6155 27.8518 50.6155Z"
                  fill="url(#paint3_linear_60896_95464)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_60896_95464"
                    x1="89.7037"
                    y1="105.811"
                    x2="45.7177"
                    y2="75.6801"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.501827" stopColor="#E74A2D" />
                    <stop offset="0.791876" stopColor="#FAB600" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_60896_95464"
                    x1="40.5002"
                    y1="54.5"
                    x2="49.5002"
                    y2="76.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.291491" stopColor="#4DAB2A" />
                    <stop offset="0.609375" stopColor="#2195F2" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_60896_95464"
                    x1="62.4765"
                    y1="99.9985"
                    x2="63.951"
                    y2="-13.5139"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.125966" stopColor="#E74A2D" />
                    <stop offset="0.553853" stopColor="#6750A4" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_60896_95464"
                    x1="13.0766"
                    y1="146.5"
                    x2="13.5163"
                    y2="21.4995"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.507168" stopColor="#FAB600" />
                    <stop offset="0.719365" stopColor="#47AB2C" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <div className="flex gap-1 items-center">
              <p className="smaller">ticet</p>
              <span>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_64342_47)">
                    <path
                      d="M5 0C2.2385 0 0 2.2375 0 4.9965C0 7.236 1.4735 9.13 3.504 9.766V8.7065C2.0385 8.113 1.004 6.6785 1.004 5.0005C1.004 2.791 2.795 1 5.004 1C7.213 1 9.004 2.791 9.004 5.0005C9.004 6.6785 7.969 8.1135 6.504 8.7065V9.7615C8.5305 9.1245 10 7.2325 10 4.9965C10 2.2375 7.7605 0 5 0ZM4.504 10.0005H5.504V9.0005H4.504V10.0005Z"
                      fill="#6750A4"
                    />
                    <path
                      d="M4.2915 6.75L3.5835 6.05L6.4165 3.25L7.125 3.95L4.2915 6.75Z"
                      fill="#6750A4"
                    />
                    <path
                      d="M2.875 5.35002L4.2915 6.75002L5 6.05002L3.5835 4.65002L2.875 5.35002Z"
                      fill="#6750A4"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_64342_47">
                      <rect width="10" height="10" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className="flex gap-2">
              <p className="smaller">{views} views</p>
              <p className="smaller">{dateUploaded}</p>
            </div>
            <p className="smaller">{duration}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
