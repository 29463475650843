import React from "react";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import VideoItem from "./videoItem";
import VideosHeader from '../../../components/common/videosHeader';
import {useNavigate} from 'react-router-dom';

const Videos = () => {

  const navigate = useNavigate();

  const videoItems = [
    {
      id: 1,
      title: "Ticet EDU",
      videoLink: "https://www.youtube.com/watch?v=CwR7sRWZ3vw",
      duration: "0:13",
      views: "1.7M",
      dateUploaded: "19/12/2024",
      description: "Ticet EDU is a learning experience platform that blends social, collaborative, and educational technologies. It provides an abundance of resources to create a thriving community of learners. The platform seeks to foster social interactions, collaborative learning, and knowledge-sharing."
    },
    {
      id: 2,
      title: "Uploading a worksheet on Ticet EDU",
      videoLink: "https://www.youtube.com/watch?v=CSXdqqKHHFE",
      duration: "2:58",
      views: "12.4K",
      dateUploaded: "19/12/2024",
      description: "Click on My Workspace in the sidebar to open My Workspace area. Navigate to the Library of the Curriculum level you want to update. Click on Worksheet under the Create tab to begin the upload process. Click Upload Files button and follow the prompts to upload files. Click on a single file and assign attributes by tagging key fields. Click Publish to complete the upload. If there are more files to upload, repeat steps 5-6 for each subsequent document."
    },
  ];

  const handleVideoItemClick = video => {
    let path = `/community/videos/${video.id}`;
    navigate(path, { state: {video} });
  }

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar label={"videos"} title={"Videos"} />
      <div className="mt-0 mx-0 lg:ml-20 min-h-screen">
        <VideosHeader />
        <div className="max-w-8xl mx-4 md:mx-8 lg:mx-20 pt-2 md:pt-4 lg:pt-6 grid auto-rows-max grid-cols-auto-fill-180 gap-6 py-8">
          {videoItems.length > 0 ? (
            videoItems.map((videoItem) => (
              <VideoItem
                title={videoItem.title}
                videoLink={videoItem.videoLink}
                key={videoItem.id}
                handleVideoItemClick={() => handleVideoItemClick(videoItem)}
                duration={videoItem.duration}
                dateUploaded={videoItem.dateUploaded}
                views={videoItem.views}
              />
            ))
          ) : (
            <div className="small">There are no videos</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
